<template>
    <div class="pageContainer targetPage flexCloumn">
        <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1" style="flex-wrap:wrap;">

                <span>选择组织：</span>
                <tchtree v-model="searchForm.class_ids" @change="searchData"></tchtree>

                <span v-if="$store.state.pmid == 215">届次：</span>
                <el-select v-if="$store.state.pmid == 215" clearable v-model="searchForm.school_year" size="small"
                    placeholder="请选择" style="max-width:200px;" @change="searchData">
                    <el-option v-for="item in schoolYears" :key="item.value" :label="item.title" :value="item.value">
                    </el-option>
                </el-select>

                <el-input placeholder="请输入学生姓名" v-model="searchForm.keyword" size="small" class="input-with-select ml10"
                    style="width:200px" clearable>
                </el-input>


                <el-select v-model="searchForm.sxqx" clearable placeholder="实习去向" size="small"
                    style="width:130px;margin-left:10px">
                    <el-option label="全部去向" value></el-option>
                    <el-option :label="item.param_desc" v-for="(item, idx) in sxqxArr" :key="idx"
                        :value="item.param_value"></el-option>
                </el-select>

                <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
                    @click="searchData">搜索</el-button>
            </div>
            <div class="flexStart" style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">
                <el-button icon="el-icon-download" size="small" @click="exportXls" type="primary"
                    plain>导出未填写学生名单</el-button>
                <el-button icon="el-icon-download" size="small" @click="exportPdfs" type="primary">导出实习鉴定图片</el-button>

                <el-button v-if="false && $store.state.config.sync_data" icon="el-icon-refresh" size="small" type="primary"
                    @click="tongbuJava">迁移老版本</el-button>


            </div>

        </el-row>
        <div class="dividerBar"></div>

        <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
            style="width:fit-content;width:100%;">
            <el-table-column type="index" label="序号" width="55px" align="center" />
            <el-table-column label="学号" prop="stu_code" width="135" align="center" />
            <el-table-column label="学生姓名" prop="stu_name" width="135" align="center" />
            <el-table-column label="专业" prop="zy_name" show-overflow-tooltip width="150" align="center" />
            <el-table-column label="班级" prop="class_name" show-overflow-tooltip width="150" align="center" />

            <el-table-column label="实习去向" prop="sxqx" width="150" align="center">
                <template slot-scope="scope">{{ sxqxObj[scope.row.sxqx] }}</template>
            </el-table-column>

            <el-table-column label="操作" width="160" align="center">
                <template slot-scope="scope">
                    <el-tag class="pointer" @click="handleView(scope.row)" type="primary" size="mini">查看</el-tag>

                    <el-popconfirm width="250" confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info"
                        iconColor="red" @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
                        <el-tag class="pointer ml10" slot="reference" size="mini" type="danger">删除</el-tag>
                    </el-popconfirm>

                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
            :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
            :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
        </el-pagination>

        <el-dialog ref="ruleForm" :visible.sync="dialogForm" :close-on-click-modal="false" @close="isRevoke = false"
            custom-class="cus_dialog" width="1000px">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;"><span style="font-weight:700;">{{ formData.stu_name }}</span> 实习鉴定表</span>
            </div>
            <div class="dialog_right">
                <div class="flexStart"
                    style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                    <div class="lineRow flexStretch" style="width:50%;">
                        <div class="cell flexStart">学号</div>
                        <div class="cell flexStart flex_1">{{ formData.stu_code }}</div>
                    </div>
                    <div class="lineRow flexStretch" style="width:50%;">
                        <div class="cell flexStart">姓名</div>
                        <div class="cell flexStart flex_1">{{ formData.stu_name }}</div>
                    </div>
                    <div class="lineRow flexStretch" style="width:50%;">
                        <div class="cell flexStart">专业</div>
                        <div class="cell flexStart flex_1">{{ formData.zy_name }}</div>
                    </div>
                    <div class="lineRow flexStretch" style="width:50%;">
                        <div class="cell flexStart">班级</div>
                        <div class="cell flexStart flex_1">{{ formData.class_name }}</div>
                    </div>


                    <div class="lineRow flexStretch" style="width:100%;">
                        <div class="cell flexStart">实习去向</div>
                        <div class="cell flexStart flex_1">{{ sxqxObj[formData.sxqx] }}</div>
                    </div>

                    <div class="lineRow flexStretch" style="width:100%;">
                        <div class="cell flexStart" style="min-height: 40px;height: unset;">实习鉴定表</div>
                        <div class="cell flexStart flex_1"
                            style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
                            <div class="flexCenter" v-for="(f, idx) in formData.files"
                                style="position:relative;height:297px;width:210px;border:1px dashed #c0ccda;border-radius:6px;margin-right:8px;margin-bottom:8px;">
                                <el-image style="max-height:100%;max-width:100%;" :src="f.fileUrl"
                                    :preview-src-list="formData.files.map(e => { return e.fileUrl })" img="cover"
                                    :z-index="3000"></el-image>
                            </div>
                            <div v-if="!formData.files || formData.files.length == 0" class="" style="width:100%;">-
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="" style="height:20px;"></div>

        </el-dialog>

    </div>
</template>

<script>
import tchtree from "../com/tchTree.vue";
import moment from "moment";

export default {
    components: { tchtree },
    data() {
        return {
            activeName: "first",
            isRevoke: false,
            searchForm: {
                ktype: "stu_name",
                keyword: "",
                sxqx: "",
                sp_status: '',
                class_ids: ""
            },
            classArr: [],
            tableData: [],
            dialogForm: false,
            modalTitle: "添加",
            formData: {},
            sxqx: ["实习", "参军", "升学/升本", "出国", "长病假", "实习结束"],
            qyxz: [], qyxzObj: {},
            salary: [], salaryObj: {},
            page: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            },
            overlap: null,
            spword: "",
            sxqxArr: [], sxqxObj: {},
            schoolYears: [],
            isSp: false,
            sign_img: '',
        };
    },
    mounted() {
        this.getSchoolYear().then(_ => {

            this.getComType().then(rr => {
                this.getList();
            })
        })
    },
    methods: {

        getSchoolYear() {
            return new Promise((resolve, reject) => {
                if (this.$store.state.pmid == 215) {

                    let m = moment().format('M')
                    let nowYear = moment().year()
                    let schoolYears = []
                    if (m >= 5) {
                        nowYear++
                    }
                    for (let i = nowYear; i >= 2019; i--) {
                        let s = (i - 1) + "-" + i
                        let s_label = s + '学年(' + (i - 5) + '届)'
                        schoolYears.push({ title: s_label, value: s })
                    }
                    this.schoolYears = schoolYears
                    this.searchForm.school_year = schoolYears[0].value
                }

                resolve()
            })
        },
        getComType() {
            return new Promise(resolve => {
                this.$http
                    .post("/api/sys_param_list", {
                        param_type: "'SHIXI_STATUS'",
                        used: 0
                    })
                    .then(res => {
                        let sxqxArr = [];
                        let sxqxObj = {};
                        for (let item of res.data) {

                            if (item.param_type == "SHIXI_STATUS") {
                                sxqxArr.push(item);
                                sxqxObj[item.param_value] = item.param_desc
                            }
                        }

                        this.sxqxArr = sxqxArr;
                        this.sxqxObj = sxqxObj;
                        console.log(sxqxObj)
                        resolve();
                    });
            });
        },

        getList() {
            let data = {};
            data.keyword = this.searchForm.keyword;
            data.sp_status = this.searchForm.sp_status;
            data.sxqx = this.searchForm.sxqx;
            data.page = this.page.current_page;
            data.pagesize = this.page.per_page;
            data.class_ids = this.searchForm.class_ids
            data.school_year = this.searchForm.school_year

            this.$http.post("/api/stu_sx_identify_tchrlist", data).then(res => {
                this.tableData = res.data.data;
                this.page = res.data.page;
            });
        },
        searchData() {
            this.page.current_page = 1;
            this.getList();
        },

        handlePageChange(page) {
            this.page.current_page = page;
            this.getList();
        },
        handleSizeChange(val) {
            this.page.current_page = 1;
            this.page.per_page = val;
            this.getList()
        },

        handleDel(row) {
            this.$http.post("/api/stu_sx_identify_delete", { id: row.id }).then(res => {
                this.$message.success("删除成功");
                this.getList();
            });
        },
        handleView(row) {
            console.log(532, row)
            this.formData = JSON.parse(JSON.stringify(row))

            if (this.formData.files && this.isJSON(this.formData.files)) {
                this.formData.files = JSON.parse(this.formData.files)
            }
            this.dialogForm = true;
        },


        tongbuJava() {
            return
            this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let loadingInstance = this.$loading({
                    lock: true,
                    text: "已发送请求，请稍等...",
                    spinner: 'el-icon-loading'
                })
                this.$http.post("/api/sync_stu_enter", {}).then(res => {
                    loadingInstance.close()
                    this.$message.success('后台同步中，请刷新查看')
                    this.getList();
                }).catch(err => {
                    this.$message.error('同步失败')
                    loadingInstance.close()
                })

            }).catch(() => {

            });
        },
        exportXls() {
            this.$http.post('/api/export_sx_identify_notfilledin_xls', {
                class_ids: this.searchForm.class_ids,
                school_year: this.searchForm.school_year
            }).then(res => {
                window.open(res.data.url, "_blank");
            });
        },
        exportPdfs() {
            this.$confirm('网速太慢或图片太多时，请耐心等待~', '温馨提示', {
                confirmButtonText: '我知道了，开始下载',
                showCancelButton: false,
                type: 'success'
            }).then(() => {
                this.$http.post('/api/export_sx_identify_imgs', {
                    class_ids: this.searchForm.class_ids,
                    school_year: this.searchForm.school_year
                }).then(res => {
                    if (res.data && res.data.length > 0) {


                        res.data.map(a => {
                            let arr = a.fileurl.split('/')
                            let file = arr[arr.length - 1]
                            let fileType = file.split('.')
                            if (fileType.length == 1) {
                                a.filename = a.filename + ".png"//判断是ios没有后缀名的文件
                            } else {
                                a.filename = a.filename + `.${fileType[fileType.length - 1]}`
                            }
                        })
                        let imgs = res.data
                        let names = imgs.map((o) => { return o.filename })
                        let urls = imgs.map((o) => { return o.fileurl })
                        let params = {
                            imgs, names, urls,
                            "pmid": window.pmid,
                        }
                        this.$http.post(this.$pdfUrl + "/exportStuSxIdentifyImgs", params).then(res => {
                            this.$message({
                                type: 'success',
                                message: '生成成功!'
                            });
                            window.open(res.data.url, "_blank")
                        })
                    } else {
                        this.$message({
                            type: 'warning',
                            message: '未查询到可导出数据!'
                        });
                    }
                });

            }).catch(() => {

            });

        },



    }
};
</script>

<style scoped lang="less">
/deep/ .el-dialog__body {
    padding: 0px 21px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
}

/deep/ .el-tabs__item {
    font-size: 18px;
}

.dialog_right {
    .lineRow {
        .cell:first-of-type {
            width: 120px;
        }

    }
}
</style>